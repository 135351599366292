<template>
  <transition>
    <button
      v-if="value"
      v-on:click="disclosureClick()"
      class="btn active"
      :class="['btn-' + disclosedClass, 'btn-' + size]"
    >
      <slot></slot>
      <slot name="disclosed"></slot>
      <cs-icon icon="chevron-down" />
    </button>
    <button
      v-else
      class="btn"
      v-on:click="disclosureClick()"
      :class="['btn-' + unDisclosedClass, 'btn-' + size]"
    >
      <slot></slot>
      <slot name="undisclosed"></slot>
      <cs-icon icon="chevron-right" />
    </button>
  </transition>
</template>

<script>
export default {
  name: 'CsDisclose',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disclosedClass: {
      type: String,
      default: 'outline-info'
    },
    unDisclosedClass: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: ''
    }
  },
  created: function () {},
  methods: {
    disclosureClick: function () {
      this.$emit('input', !this.value)
      this.$emit('update:value', !this.value)
      this.$emit('handle-click', !this.value)
    }
  }
}
</script>

<style scoped></style>
